<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="120px"
    >
      <div class="tablePart">
        <el-form-item label="公司" prop="companyId">
          <el-select
            v-model="dataForm.companyId"
            filterable
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="补助级别名称" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="补助级别名称"
          />
        </el-form-item>
        <el-form-item label="补助形式" prop="subsidyType">
          <el-select
            v-model="dataForm.subsidyType"
            filterable
            @change="checkType()"
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in subsidyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="补助金额" prop="amount">
          <el-input
            v-model="dataForm.amount"
            :disabled="disabled || dataList.length > 0"
            placeholder="补助额度"
          />
        </el-form-item>

        <div class="tablePart" v-if="this.dataForm.subsidyType === 1">
          <el-button
            type="primary"
            @click="addDataList()"
            class="el-icon-add-location"
            >新增</el-button
          >
        </div>
        <div class="form-table" v-if="this.dataForm.subsidyType === 1">
          <div class="table-header">
            <div class="table-header-item">时段</div>
            <div class="table-header-item headWidth150">额度</div>
            <div class="table-header-item headWidth150">操作</div>
          </div>
          <div class="table-body">
            <div
              v-for="periodLevel in dataList"
              :key="periodLevel.orgFreezerId"
              class="table-body-wrap"
            >
              <div class="table-body-item">
                <el-select v-model="periodLevel.periodId">
                  <el-option
                    v-for="(item, index) in periodList"
                    :key="index"
                    :label="item.withTimeName"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div class="table-body-item headWidth150">
                <el-input-number
                  v-model="periodLevel.periodAmount"
                  class="inputNumberInside"
                  @change="countAmount()"
                ></el-input-number>
              </div>
              <div class="table-body-item headWidth150">
                <i
                  @click="handleDeleteItem(periodLevel)"
                  class="el-icon-delete"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';
import {
  getSubsidyList,
  getPeriodList,
  getCompanyList,
} from '@/utils/options.js';
import { keys, pick } from 'lodash';

export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        companyId: '',
        name: '',
        amount: '',
        subsidyType: '', //补助id
      },
      companyList: [],
      subsidyList: [],
      periodList: [],
      dataList: [],
      dataRule: {
        name: [
          { required: true, message: '补助名称不能为空', trigger: 'blur' },
        ],
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
        subsidyType: [
          { required: true, message: '补助形式不能为空', trigger: 'blur' },
        ],
        amount: [
          { required: true, message: '补助金额不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.getCompanyList();
    this.getPeriodList();
    this.getSubsidyList();
  },

  methods: {
    init(id, disabled) {
      this.dataList = [];
      this.disabled = disabled;
      this.dataForm.id = id || null;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/companyLevel/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.companyLevel, keys(this.dataForm));
              this.dataList = data.companyLevel.levelPeriod;
            }
          });
        }
      });

      this.$init({
        before: () => {
          this.disabled = disabled;
          this.dataForm.id = id || null;
        },
        url: id ? `/tc/companyLevel/info/${id}` : null,
        after: (data) => {
          this.dataForm = pick(data.companyLevel, keys(this.dataForm));
        },
      });
    },
    // 表单提交,只提交部分参数
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let levelList = this.dataList.map((item) => {
            return {
              companyLevelId: item.companyLevelId,
              periodId: item.periodId,
              periodAmount: item.periodAmount,
            };
          });
          this.$http({
            url: `/tc/companyLevel/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              id: this.dataForm.id,
              name: this.dataForm.name,
              amount: this.dataForm.amount,
              companyId: this.dataForm.companyId,
              subsidyType: this.dataForm.subsidyType,
              levelPeriod: levelList.filter((item) => item.periodAmount > 0),
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.status === 422) {
              this.$message({
                message: data.errors.levelId,
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
    //获取补助方式列表
    getSubsidyList() {
      getSubsidyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.subsidyList = data.list;
        }
      });
    },

    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    getPeriodList() {
      getPeriodList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },

    checkType() {
      if (this.dataForm.subsidyType === 1) {
        this.addDataList();
      } else {
        this.dataList = [];
      }
    },
    addDataList() {
      this.dataList.push({
        timeId: +new Date(),
        companyLevelId: '',
        periodId: '',
        periodAmount: '',
      });
    },
    handleDeleteItem(item) {
      this.$warning(`是否确认删除${item.periodWithTimeName}?`).then(() => {
        if (item.id) {
          this.dataList = this.dataList.filter((i) => i.id !== item.id);
        } else {
          this.dataList = this.dataList.filter((i) => i.timeId !== item.timeId);
        }
        this.countAmount();
      });
    },
    countAmount() {
      let sum = 0;
      this.dataList.forEach((level) => (sum += level.periodAmount));
      this.dataForm.amount = sum;
    },
  },
};
</script>
